import {
  Theme,
  getCustomTheme,
  valiotTheme,
  // valiotTheme
} from '@valiot/core'

const customTheme = {
  ...valiotTheme,
  customColors: {
    Items: {
      // MaterialCatalogue SERIE
      10188860: ' #008000',
      10206469: ' #008000',
      10244827: ' #008000',
      10244829: ' #008000',
      10244831: ' #008000',
      10304230: ' #008000',
      10327426: ' #008000',
      10345818: ' #008000',
      10347318: ' #008000',
      10377639: ' #008000',
      10377641: ' #008000',
      10417735: ' #008000',
      10417736: ' #008000',
      10417737: ' #008000',
      10417738: ' #008000',
      10417739: ' #008000',
      10439337: ' #008000',
      10465006: ' #008000',
      10475523: ' #008000',
      10477394: ' #008000',
      10477395: ' #008000',
      10477396: ' #008000',
      10477397: ' #008000',
      10469799: ' #008000',
      10316526: ' #008000',
      10327223: ' #008000',
      10347316: ' #008000',
      10439149: ' #008000',
      10439151: ' #008000',
      10439152: ' #008000',
      10475519: ' #008000',
      10475520: ' #008000',
      10475529: ' #008000',
      10327484: ' #008000',
      10395320: ' #008000',
      '10417736V': '#008000',
      10495618: ' #008000',
      10495619: ' #008000',
      10349079: ' #008000',
      10064892: ' #008000',
      10186358: ' #008000',
      10217969: ' #008000',
      10224481: ' #008000',
      10229021: ' #008000',
      10231025: ' #008000',
      10231026: ' #008000',
      10231027: ' #008000',
      10231028: ' #008000',
      10233081: ' #008000',
      10233093: ' #008000',
      10252218: ' #008000',
      10272053: ' #008000',
      10280761: ' #008000',
      10298385: ' #008000',
      10298386: ' #008000',
      10319681: ' #008000',
      10319682: ' #008000',
      10355520: ' #008000',
      10355521: ' #008000',
      10372418: ' #008000',
      10373621: ' #008000',
      10373622: ' #008000',
      10373623: ' #008000',
      10373626: ' #008000',
      10388296: ' #008000',
      10391145: ' #008000',
      10391146: ' #008000',
      10406637: ' #008000',
      10417637: ' #008000',
      10443667: ' #008000',
      10461324: ' #008000',
      10461325: ' #008000',
      10462496: ' #008000',
      10462501: ' #008000',
      10462508: ' #008000',
      10462509: ' #008000',
      10462510: ' #008000',
      10462511: ' #008000',
      10462512: ' #008000',
      10462513: ' #008000',
      10462514: ' #008000',
      10462658: ' #008000',
      10462660: ' #008000',
      10462662: ' #008000',
      10465011: ' #008000',
      10465012: ' #008000',
      10465013: ' #008000',
      10465014: ' #008000',
      10469099: ' #008000',
      10469100: ' #008000',
      10473262: ' #008000',
      10473263: ' #008000',
      10473264: ' #008000',
      10473265: ' #008000',
      10474443: ' #008000',
      10481547: ' #008000',
      10481548: ' #008000',
      10484165: ' #008000',
      10484763: ' #008000',
      10458905: ' #008000',
      10472407: ' #008000',
      10469107: ' #008000',
      10475181: ' #008000',
      10220692: ' #008000',
      10226054: ' #008000',
      10226056: ' #008000',
      10232237: ' #008000',
      10233072: ' #008000',
      10233365: ' #008000',
      10263292: ' #008000',
      10265771: ' #008000',
      10300476: ' #008000',
      10302688: ' #008000',
      10325003: ' #008000',
      10325107: ' #008000',
      10330663: ' #008000',
      10332800: ' #008000',
      10354055: ' #008000',
      10354056: ' #008000',
      10354057: ' #008000',
      10354058: ' #008000',
      10354059: ' #008000',
      10354060: ' #008000',
      10354063: ' #008000',
      10354064: ' #008000',
      10359609: ' #008000',
      10359612: ' #008000',
      10363289: ' #008000',
      10363290: ' #008000',
      10364081: ' #008000',
      10364082: ' #008000',
      10370080: ' #008000',
      10370081: ' #008000',
      10370082: ' #008000',
      10370083: ' #008000',
      10370086: ' #008000',
      10370087: ' #008000',
      10372419: ' #008000',
      10378980: ' #008000',
      10378981: ' #008000',
      10388298: ' #008000',
      10393118: ' #008000',
      10393119: ' #008000',
      10395973: ' #008000',
      10395974: ' #008000',
      10402421: ' #008000',
      10402428: ' #008000',
      10402649: ' #008000',
      10402651: ' #008000',
      10402703: ' #008000',
      10402747: ' #008000',
      10405523: ' #008000',
      10405524: ' #008000',
      10416390: ' #008000',
      10417632: ' #008000',
      10417641: ' #008000',
      10417645: ' #008000',
      10417740: ' #008000',
      10417741: ' #008000',
      10419144: ' #008000',
      10419145: ' #008000',
      10420021: ' #008000',
      10420070: ' #008000',
      10420071: ' #008000',
      10420072: ' #008000',
      10420073: ' #008000',
      10432460: ' #008000',
      10432461: ' #008000',
      10434713: ' #008000',
      10441468: ' #008000',
      10454616: ' #008000',
      10462515: ' #008000',
      '10471245P': ' #008000',
      10475950: ' #008000',
      10475951: ' #008000',
      10475952: ' #008000',
      10479131: ' #008000',
      10479132: ' #008000',
      10484729: ' #008000',
      10484730: ' #008000',
      8159010325: ' #008000',
      8684010333: ' #008000',
      10330377: ' #008000',
      10431563: ' #008000',
      10434890: ' #008000',
      10465400: ' #008000',
      10472200: ' #008000',
      10489352: ' #008000',
      10489351: ' #008000',
      10462500: ' #008000',
      10478403: ' #008000',
      10416576: ' #008000',
      10238284: ' #008000',
      10261824: ' #008000',
      10253282: ' #008000',
      10360133: ' #008000',
      10301980: ' #008000',
      10328804: ' #008000',
      10233069: ' #008000',
      10379658: ' #008000',
      10388295: ' #008000',
      10432457: ' #008000',
      10495620: ' #008000',
      10501756: ' #008000',
      10501582: ' #008000',
      10501759: ' #008000',
      10495450: ' #008000',
      10495449: ' #008000',
      10493452: ' #008000',
      10494405: ' #008000',
      10493969: ' #008000',
      10261828: ' #008000',
      10493970: ' #008000',
      10493965: ' #008000',
      10356982: ' #008000',
      10509396: ' #008000',
      10506492: ' #008000',
      10506493: ' #008000',
      10495660: ' #008000',
      10508299: ' #008000',
      10508300: ' #008000',
      10508297: ' #008000',
      10507017: ' #008000',
      10511806: ' #008000',
      10511808: ' #008000',
      10511807: ' #008000',
      10507203: ' #008000',
      10507204: ' #008000',
      10510212: ' #008000',
      10507205: ' #008000',
      10507206: ' #008000',
      10512147: ' #008000',
      10512148: ' #008000',
      10512149: ' #008000',
      10512150: ' #008000',
      10507379: ' #008000',
      10507380: ' #008000',
      10507381: ' #008000',
      10507382: ' #008000',
      10507010: ' #008000',
      10507016: ' #008000',
      10514501: ' #008000',
      10520206: ' #008000',
      10511789: ' #008000',
      10515168: ' #008000',
      10526528: ' #008000',
      10526529: ' #008000',
      10515167: ' #008000',
      10527180: ' #008000',
      10526273: ' #008000',
      10526274: ' #008000',
      10495623: ' #008000',
      10495622: ' #008000',
      10223775: ' #008000',
      10474150: ' #008000',
      10474149: ' #008000',
      10493461: ' #008000',
      10437563: ' #008000',
      10381192: ' #008000',
      10437585: ' #008000',
      10319199: ' #008000',
      10510155: ' #008000',
      // MaterialCatalogue ES
      10221112: ' #800080',
      10347313: ' #800080',
      10438722: ' #800080',
      10238289: ' #800080',
      10327483: ' #800080',
      10243907: ' #800080',
      10278832: ' #800080',
      10366649: ' #800080',
      10054230: ' #800080',
      10084078: ' #800080',
      10173385: ' #800080',
      10186348: ' #800080',
      10232986: ' #800080',
      10253281: ' #800080',
      10259533: ' #800080',
      10260280: ' #800080',
      10282509: ' #800080',
      10475275: ' #800080',
      10079131: ' #800080',
      10232239: ' #800080',
      10233274: ' #800080',
      10272266: ' #800080',
      10482429: ' #800080',
      10185183: ' #800080',
      10315635: ' #800080',
      10065307: ' #800080',
      10185169: ' #800080',
      10259535: ' #800080',
      10261822: ' #800080',
      10236056: ' #800080',
      10253283: ' #800080',
      10224485: ' #800080',
      10319995: ' #800080',
      10265960: ' #800080',
      10323879: ' #800080',
      10250871: ' #800080',
      10230655: ' #800080',
      10064071: ' #800080',
      10395228: ' #800080',
      10505406: ' #800080',
      10470957: ' #800080',
      10352438: ' #800080',
      10444343: ' #800080',
      8683010333: ' #800080',
      10236061: ' #800080',
      10320637: ' #800080',
      10319986: ' #800080',
      10347314: ' #800080',
      10243908: ' #800080',
      10186149: ' #800080',
      10252215: ' #800080',
      8500010315: ' #800080',
      10362070: ' #800080',
      10394889: ' #800080',
      10252160: ' #800080',
      10339173: ' #800080',
      10516292: ' #800080',
      10516285: ' #800080',
      10230656: ' #800080',
      10517187: ' #800080',
      10517003: ' #800080',
      10517002: ' #800080',
      10517774: ' #800080',
      10232241: ' #800080',
      10327485: ' #800080',
      8887010318: ' #800080',
      10345817: ' #800080',
      10232690: ' #800080',
      10186226: ' #800080',
      10362075: ' #800080',
      10232182: ' #800080',
      10408893: ' #800080',
      10524704: ' #800080',
      10524694: ' #800080',
      10230652: ' #800080',
      10417627: ' #800080',
      10317751: ' #800080',
      10490068: ' #800080',
      10438721: ' #800080',
      10315633: ' #800080',
      10297728: ' #800080',
      VIP003: ' #800080',
      10527119: ' #800080',
      10527123: ' #800080',
      10527125: ' #800080',
      10186221: ' #800080',
      10532874: ' #800080',
      10498979: ' #800080',
      NMAGKNMEX: ' #800080',
      10511527: ' #800080',
      10511537: ' #800080',
      10537025: ' #800080',
      10181082: ' #800080',
      10185185: ' #800080',
      10232235: ' #800080',
      10232791: ' #800080',
      10238287: ' #800080',
      10347321: ' #800080',
      10262495: ' #800080',
      10544100: ' #800080',
      '10532729 - Corte': ' #800080',
      10542563: ' #800080',
      10536744: ' #800080',
      10228200: ' #800080',
      10263496: ' #800080',
      10261161: ' #800080',
      10077194: ' #800080',
      // MaterialCatalogue PROTOTIPO
      10330378: ' #FFFF00',
      10492484: ' #FFFF00',
      10492485: ' #FFFF00',
      10492486: ' #FFFF00',
      10492487: ' #FFFF00',
      10492320: ' #FFFF00',
      10492321: ' #FFFF00',
      10492322: ' #FFFF00',
      10492323: ' #FFFF00',
      10492324: ' #FFFF00',
      10524303: ' #FFFF00',
      10524304: ' #FFFF00',
      10517069: ' #FFFF00',
      10329150: ' #FFFF00',
      10527113: ' #FFFF00',
      10527114: ' #FFFF00',
      10532629: ' #FFFF00',
      10526997: ' #FFFF00',
      10526998: ' #FFFF00',
      10501909: ' #FFFF00',
      10432458: ' #FFFF00',
      10524426: ' #FFFF00',
      '2A106015': ' #FFFF00',
      '2A106016': ' #FFFF00',
      310152973: ' #FFFF00',
      10509626: ' #FFFF00',
      10222010: ' #FFFF00',
      '10462510TYA': ' #FFFF00',
      // MaterialCatalogue F5APQP
      10432459: ' #0000FF',
      10495448: ' #0000FF',
      10504540: ' #0000FF',
      10509397: ' #0000FF',
      10517009: ' #0000FF',
      10517010: ' #0000FF',
      10515839: ' #0000FF',
      10515840: ' #0000FF',
      10515841: ' #0000FF',
      10516694: ' #0000FF',
      10516644: ' #0000FF',
      10522513: ' #0000FF',
      10522514: ' #0000FF',
      10520359: ' #0000FF',
      10520360: ' #0000FF',
      10525338: ' #0000FF',
      10518040: ' #0000FF',
      10519761: ' #0000FF',
      10519762: ' #0000FF',
      10522517: ' #0000FF',
      10522516: ' #0000FF',
      10522520: ' #0000FF',
      10522519: ' #0000FF',
      10519705: ' #0000FF',
      10524305: ' #0000FF',
      10452111: ' #0000FF',
      10524306: ' #0000FF',
      10524417: ' #0000FF',
      10524418: ' #0000FF',
      10524420: ' #0000FF',
      10524421: ' #0000FF',
      10524422: ' #0000FF',
      10524423: ' #0000FF',
      10524424: ' #0000FF',
      10527115: ' #0000FF',
      10527273: ' #0000FF',
      10527274: ' #0000FF',
      10526996: ' #0000FF',
      10527278: ' #0000FF',
      10529582: ' #0000FF',
      10529486: ' #0000FF',
      10526949: ' #0000FF',
      10526950: ' #0000FF',
      10519692: ' #0000FF',
      10532728: ' #0000FF',
      10532729: ' #0000FF',
      10524425: ' #0000FF',
      10526951: ' #0000FF',
      10280800: ' #0000FF',
      10522518: ' #0000FF',
      10522859: ' #0000FF',
      10523483: ' #0000FF',
      // MaterialCatalogue REFACCION
      10238288: ' #808080',
      10349088: ' #808080',
      10173387: ' #808080',
      10054186: ' #808080',
      8305010315: ' #808080',
      10230646: ' #808080',
      10536730: ' #808080',
      10330665: ' #808080',
      8501010315: ' #808080',
      8309010315: ' #808080',
    },
    stops: {
      stop: '#FF0000',
    },
  },
}
export const appTheme: Theme = getCustomTheme({
  // you can set a default theme as a base for your new theme (defaults to "valiotTheme"):
  baseTheme: customTheme,
  overrides: {
    // update this object to setup a custom theme for the app
    colors: {
      brand: {
        // base: '#FF0000',
        // shade: '#FF2020'
      },
    },
  },
})
