import React, { lazy } from 'react'
import { createRoot } from 'react-dom/client'
import settings from './settings.js'
import { ValiotApp, getStaticPagesFromSettings } from '@valiot/app'
import { appTheme } from './appTheme.js'
import './index.css'

function screenExtractor(screenComponentPath: string) {
  const paths = screenComponentPath.split('/')
  switch (paths.length) {
    case 2:
      return lazy(() => import(`./screens/${paths[0]}/${paths[1]}.tsx`))
    case 3:
      return lazy(() => import(`./screens/${paths[0]}/${paths[1]}/${paths[2]}.tsx`))
    case 4:
      return lazy(() => import(`./screens/${paths[0]}/${paths[1]}/${paths[2]}/${paths[3]}.tsx`))
    default:
      throw new Error(`Invalid screen component path: ${screenComponentPath}`)
  }
}

const root = createRoot(document.getElementById('root')!)

root.render(
  <React.StrictMode>
    <ValiotApp
      settings={settings}
      theme={appTheme}
      staticPages={getStaticPagesFromSettings(settings, screenExtractor)}
    />
  </React.StrictMode>,
)
